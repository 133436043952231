import { combineReducers } from 'redux';
import { biltyNumber, BiltyReducer, getRouteData,  singleData, trackBilty } from './BiltyReducer';
import {CityReducer, RouteReducer} from './CityReducer';
import { DriverReducer, VehicleReducer } from './DriverVehicle';
import EcommerceReducer from './EcommerceReducer';
import NavigationReducer from './NavigationReducer';
import NeeReducer from './NeeReducer';
import { NorReducer, PkgReducer } from './NorReducer';
import NotificationReducer from './NotificationReducer';
import { QuotListReducer, QuotReducer } from './QuotationReducer';
import { MemoPrintReducer, printNumberReducer } from './PrintData';
import LoadingReducer from './LoadingRuducer';
import { MemoSingle } from './Memoreducer';
import { AllBiltyReport } from './AllBiltyReportReducer';
import { AllMemoReport } from './AllMemoReportReducer';
import { ReceiveMemoReducer } from './ForMemoReceive';
import { billData, billList } from './BillReducer';
// import QuotReducer from './QuotationReducer';

const RootReducer = combineReducers({
  nordata: NorReducer,
  needata: NeeReducer,
  citydata: CityReducer,
  collectdata: BiltyReducer,
  quotdata: QuotReducer,
  quotlist: QuotListReducer,
  biltynumber: biltyNumber,
  singledata: singleData,
  pkgdata: PkgReducer,
  route: RouteReducer,
  getRouteData: getRouteData,
  driverdata:DriverReducer,
  vehicledata:VehicleReducer,



  printNumber:printNumberReducer,


  
  memoprintdata:MemoPrintReducer,
  memosingledata:MemoSingle,



  biltyreport:AllBiltyReport,
  trackBilty:trackBilty,
  memoreport:AllMemoReport,

  memoreceivedata:ReceiveMemoReducer,



  billData:billData,
  billList:billList,


  loadingData:LoadingReducer,



  
  notifications: NotificationReducer,
  navigations: NavigationReducer,
  ecommerce: EcommerceReducer,
});

export default RootReducer;
